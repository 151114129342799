import React, { Component } from "react";
import { Fragment } from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { frontUrl, storageUrl } from "../../common";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={"next slick-arrow fa fa-angle-right"}
            style={{ ...style, borderRadius: "25px", fontSize: "50px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={"prev slick-arrow fa fa-angle-left"}
            style={{ ...style, borderRadius: "25px", fontSize: "50px" }}
            onClick={onClick}
        />
    );
}

const OurRange = ({ productlist }) => {

    const settings = {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 5,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [{
            breakpoint: 1169,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 969,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }
        ]
    };
    return (
        <Fragment>
            <div style={{ backgroundImage: `url("${storageUrl}/configuration/dot-bg.jpg")` }}
      className="product-area pt-40 hm9-section-padding bg-shaded-light"
    >
                <div class="container-fluid">
                    <div className="welcome-content text-center" >
                        <h1>Discover from our range of bicycles</h1>
                    </div>
                    <div class="featured-slider single-products text-center">
                        <Slider {...settings}>
                            {productlist.items.length > 0 ? (
                                productlist.items.map((product) => {
                                    return (
                                        <div class="single-slide">
                                            <div class="padding30">
                                                <div class="product-item">
                                                    <div class="pro-img">
                                                        <Link
                                                            to={
                                                                process.env.PUBLIC_URL +
                                                                "/product/" +
                                                                product.url_key
                                                            }
                                                            onClick={(event) =>
                                                                localStorage.setItem(
                                                                    "pro" + product.url_key,
                                                                    product.name
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    product.images != undefined &&
                                                                        product.images.length > 0
                                                                        ? product.images[0].large_image_url
                                                                        : ""
                                                                }
                                                                alt=""
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div class="actions-btn">
                                                        <ul class="clearfix">
                                                            <li>
                                                                <Link
                                                                    to={
                                                                        process.env.PUBLIC_URL +
                                                                        "/product/" +
                                                                        product.url_key
                                                                    }
                                                                    onClick={(event) =>
                                                                        localStorage.setItem(
                                                                            "pro" + product.url_key,
                                                                            product.name
                                                                        )
                                                                    }
                                                                >
                                                                    {product.name}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="product-title">
                                                        <Link
                                                            to={
                                                                process.env.PUBLIC_URL +
                                                                "/product/" +
                                                                product.url_key
                                                            }
                                                            onClick={(event) =>
                                                                localStorage.setItem(
                                                                    "pro" + product.url_key,
                                                                    product.name
                                                                )
                                                            }
                                                        >

                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no_record_com"> No Product available!</div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

const mapStateToProps = (state, ownProps) => {
    return {
        productlist: state.product,
    };
};

export default connect(mapStateToProps)(OurRange);
