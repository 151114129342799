import PropTypes from "prop-types";
import React from "react";

const TestimonialOneSingle = ({ data, sliderClass, testimonialClass }) => {
  return (
    <div
      className={`${
        testimonialClass ? testimonialClass : "single-testimonial"
      } ${sliderClass ? sliderClass : ""}`}
    >
      <div className="row">
        <div className="col-lg-6 text-center">
          <img src={process.env.PUBLIC_URL + data.image} alt="" />
        </div>
        <div className="col-lg-6 client-info">
          <i className="fa fa-quote-left" />
          <p>{data.content}</p>
          <div className="client-info">
            <i className="fa fa-quote-right" />
            <h5>{data.customerName}</h5>
            <span>{data.title}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

TestimonialOneSingle.propTypes = {
  data: PropTypes.object,
  sliderClass: PropTypes.string
};

export default TestimonialOneSingle;
