import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import {frontUrl} from "../../common"

const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
  iconWhiteClass,
  cart
}) => {

  console.log("Icon cart update check ",cart.cartlist.items)
  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
//  console.log("authtication data",JSON.parse(localStorage.getItem('auth')).token)
 const auth = localStorage.getItem('auth') !=undefined?JSON.parse(localStorage.getItem('auth')):'';
  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      {/* <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action={frontUrl+"/catalog/root"}>
            <input type="text" placeholder="Search" name="name" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div> */}

      {/* <div className="same-style account-setting d-none d-lg-block">
        <button
          className="account-setting-active"
          onClick={(e) => handleClick(e)}
        >
          <i className="pe-7s-user" />
        </button>
        <div className="account-dropdown">
          <ul>
           {auth.token !=undefined? <React.Fragment><li>
              <Link to={process.env.PUBLIC_URL + "/my-account"}>my account</Link>
            </li>
             <li>
             <Link to="#" onClick={() => { localStorage.clear(); window.location.href = frontUrl+'/login-register'}}>Logout </Link>
           </li></React.Fragment>
            :<React.Fragment><li>
            <Link to={process.env.PUBLIC_URL + "/login-register"}>Login</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/login-register"}>Register</Link>
          </li></React.Fragment>
           }
          </ul>
        </div>
      </div> */}

      {/* <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>

        <Link to={process.env.PUBLIC_URL + "/"}>
          <i className="pe-7s-edit" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>
      </div> */}

      {/* <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>

        <Link to={process.env.PUBLIC_URL + "/"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div> */}

      {/* <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-cart" />
          <span className="count-style">
            {cart.cartlist.items && cart.cartlist.items.length ? cart.cartlist.items.length : 0}
          </span>
        </button>
        <MenuCart
          cartData={cart.cartlist}
          currency={currency}
          deleteFromCart={deleteFromCart}
        />
      </div> */}


      {/* <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cart.cartlist.items && cart.cartlist.items.length ? cart.cartlist.items.length : 0}
          </span>
        </Link>

        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/"}>
          <i className="pe-7s-cart" />
          <span className="count-style">
            {cart.cartlist.items && cart.cartlist.items.length ? cart.cartlist.items.length : 0}
          </span>
        </Link>
      </div> */}

      <div className="btn btn-primary call-us-btn bg-white border-0 mx-1" id="call-us-btn"><a href="tel:+911614353000" className="text-dark">Call Us</a></div>
      <div className="btn btn-primary mail-us-btn border-0 mx-1" id="mail-us-btn"><a href="https://www.amazon.in/s?i=merchant-items&me=A3O10837P0ZLK9&rh=p_4%3AHUGE&dc&marketplaceID=A21TJRUUN4KGV&qid=1638334415&ref=sr_nr_p_4_1" target="_blank" className="text-white">Buy Online</a></div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlist.items,
    compareData: state.compareData,
    cart:state.cart
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
