import PropTypes from "prop-types";
import React from "react";
import { Fragment } from "react";
import { frontUrl } from "../../common";
import {

  getProductFilters,
} from "../../redux/product/productActions";
import { connect } from "react-redux";
const AdvanceSearch = ({ category, filters, getProductFilters }) => {
  console.log("filters coming here", filters);

  let ageindex = filters.items.findIndex(function (fil) {
    return fil.code == "gender";
  });

  let heightindex = filters.items.findIndex(function (fil) {
    return fil.code == "height";
  });
  const submitsearchform = (event) => {
    event.preventDefault();
    var params = "";
    if (document.getElementById("cat").value != "") {
      params =
        frontUrl +
        "/catalog" +
        "/" +
        document.getElementById("cat").value +
        "/";
    } else {
      params = frontUrl + "/catalog/root";
    }
    if (document.getElementById("gender").value != "") {
      params = params + "?gender=" + document.getElementById("gender").value;
    }

    if (document.getElementById("height").value != "") {
      if (params.indexOf("?") !== -1) {
        params = params + "&height=" + document.getElementById("height").value;
      } else {
        params = params + "?height=" + document.getElementById("height").value;
      }
    }

    window.location.href = params;
  };
  /*** on Change Category change */
    const categorychange = (event) => {
      
        getProductFilters(event.target.value)
  };
  return (
    <Fragment>
      <div class="s-find-bike bg-gray-3">
        <div class="container-fluid">
          <form class="find-bike-form">
            <div className="welcome-content text-center">
              <h1>Find Your Perfect Bicycle</h1>
            </div>
            <ul class="form-wrap">
              <li>
                <label>Select Type</label>
                <select class="nice-select" id="cat" onChange={categorychange}>
                  <option selected="selected" value="">
                    Select Type
                  </option>
                  {category.items.map((value) => {
                    
                    return <option value={value.slug}>{value.name}</option>;
                  })}
                </select>
              </li>
              <li>
                <label>Select Gender</label>
                <select class="nice-select" id="gender">
                  <option selected="selected" value="">
                    Select Gender
                  </option>
                  {ageindex > -1
                    ? filters.items[ageindex].options.map((value) => {
                      if (!filters.specific.includes(value.id.toString())) {
                        return '';
                      }
                           return (
                             <option value={value.id}>
                               {value.admin_name}
                             </option>
                           );
                      })
                    : ""}
                </select>
              </li>
              <li>
                <label>Select Height</label>
                <select class="nice-select" id="height">
                  <option selected="selected" value="">
                    Select Height
                  </option>
                  {heightindex > -1
                    ? filters.items[heightindex].options.map((value) => {
                       if (!filters.specific.includes(value.id.toString())) {
                         return "";
                       }
                      if (value.label == '') {
                        return '';
                      }
                        return (
                          <option value={value.id}>{value.admin_name}</option>
                        );
                      })
                    : ""}
                </select>
              </li>
              <li>
                <a href="#" class="btn" onClick={submitsearchform}>
                  <span>Find Now!</span>
                </a>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
      category: state.category,
      filters: state.productfilter

  
  };
};

const mapDispatchToProps = {

  getProductFilters: getProductFilters,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearch);