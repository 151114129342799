import PropTypes from "prop-types";
import React from "react";

const SectionTitleWithText = ({ spaceTopClass, spaceBottomClass, content }) => {
  return (
    <div
      className={`welcome-area hm9-section-padding ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        {/* <div className="welcome-content" dangerouslySetInnerHTML={{__html: content}}> */}
        <div className="welcome-content about-text text-center" >
          {/* <h5>Who Are We</h5> */}
            <div dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
      </div>
    </div>
  );
};

SectionTitleWithText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  content: PropTypes.any,
};

export default SectionTitleWithText;
