import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";
import HeroSliderFourteen from "../../wrappers/hero-slider/HeroSliderFourteen";
// import CategoryOneSlider from "../../wrappers/category/CategoryOneSlider";
import CategorySixSingle from "../../components/category/CategorySixSingle";
// import SectionTitleWithText from "../../components/section-title/SectionTitleWithText";
import AdvanceSearch from "../../components/custom/AdvanceSearch";
import NewLaunches from "../../components/custom/NewLaunches";
import OurRange from "../../components/custom/OurRange";
// import Advantage from "../../components/custom/Advantage";
// import SaleItem from "../../components/custom/SaleItem";
import HomeAbout from "../../components/custom/HomeAbout";
import TabProductThree from "../../wrappers/product/TabProductThree";
import TestimonialOne from "../../wrappers/testimonial/TestimonialOne";
// import FeatureIconTwo from "../../wrappers/feature-icon/FeatureIconTwo";
// import { getnewproduct, getBestSellerProduct, getSaleItemProduct } from "../../redux/product/productActions";
import {
  getnewproduct,
  getProductFilters,
} from "../../redux/product/productActions";
import { getBrands } from "../../redux/product"

// const HugeHome = ({ getBrands, category, getnewproduct, getBestSellerProduct, getSaleItemProduct }) => {
//     useEffect(() => {
//         getnewproduct({ limit: 10, category: 1, attribute: "&new=1" });
//         getBestSellerProduct({ limit: 5, category: 1, attribute: "&bestseller=1" });
//         getSaleItemProduct({ limit: 5, category: 1, attribute: "&saleitem=1" });
//     }, []);
const HugeHome = ({
  getBrands,
  category,
  getnewproduct,
  getProductFilters,
}) => {
  useEffect(() => {
    getnewproduct({ limit: 10, category: 1, attribute: "&featured=1" });
    getProductFilters("root");
  }, []);
  console.log("categories  all data coming here", category);
  return (
    <Fragment>
      <MetaTags>
        <title>Huge Bicycles</title>
        <meta name="description" content="" />
      </MetaTags>
      <LayoutOne>
        <HeroSliderFourteen />
        <AdvanceSearch />
        <NewLaunches />
        <OurRange />
        {/* <SectionTitleWithText spaceTopClass="pt-100" spaceBottomClass="pb-50" /> */}
        {/* <FeatureIconTwo spaceTopClass="pt-50" spaceBottomClass="pb-50" /> */}
        {/* <CategoryOneSlider spaceTopClass="pt-100" spaceBottomClass="pb-95" /> */}
        {/* <TabProductThree spaceBottomClass="pb-30"  /> */}
        <CategorySixSingle data={category.items} />
        <HomeAbout />
        {/* <TestimonialOne
                spaceTopClass="pt-100"
                spaceBottomClass="pb-95"
                spaceLeftClass="ml-70"
                spaceRightClass="mr-70"
                /> */}
      </LayoutOne>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
    return {
        category: state.category,
        product: state.productData
    }
}
// const mapDispatchToProps = {
//     getBrands: getBrands,
//     getnewproduct: getnewproduct,
//     getBestSellerProduct: getBestSellerProduct,
//     getSaleItemProduct: getSaleItemProduct
// }
const mapDispatchToProps = {
    getBrands: getBrands,
    getnewproduct: getnewproduct,
    getProductFilters:getProductFilters
}
export default connect(mapStateToProps, mapDispatchToProps)(HugeHome);