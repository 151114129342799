import PropTypes from "prop-types";
import React from "react";
import { frontUrl, storageUrl } from "../../common";
import { Link } from "react-router-dom";

const CategorySixSingle = ({ data }) => {

  console.log("category data coming here",data);


  const catRender =(listdata,start,stop,classes)=>{
             

    return (listdata.map(function(data,index)
      {
          
          if(start>1 && start>index)
          {
            return '';
          }
          if(stop<index)
          {
            return '';
          }

          console.log("start and index",start,index);
          console.log("end and index",stop,index);

        return(<div className={classes[index]}>
    <div className="product-wrap-4 ">
    <Link to={process.env.PUBLIC_URL + "/catalog/" + data.slug}> 
    <div class="grid">
        <figure class="effect-duke">  
          <img
            src={
              data.image_url
            }
            alt="img06"
          />
          {/* <figcaption>
           <p>{data.name}</p>
          </figcaption> */}
        </figure>
        
      </div>
      </Link>
    </div>
          </div>)})

    )

          }

  return (
    // <div className="col-lg-4 col-md-6 mb-30">
    //   <div className="category-grid">
    //     <div className="category-grid__image">
    //       <Link to={process.env.PUBLIC_URL + data.url}>
    //         <img
    //           src={process.env.PUBLIC_URL + data.image}
    //           alt=""
    //           className="img-fluid"
    //         />
    //       </Link>
    //     </div>
    //     <div className="category-grid__content">
    //       <Link to={process.env.PUBLIC_URL + data.url}>{data.title}</Link>
    //     </div>
    //   </div>
    // </div>
    <div className="product-area hm6-section-padding pt-40 pb-40" style={{ backgroundImage: `url("${storageUrl}/configuration/dot-bg.jpg")` }}>
          <div className="container">
            <div className="welcome-content text-center " >
                <h1>Huge Categories</h1>  
              </div>
            <div className="row ">
              {/* <div className="welcome-content text-center pb-50" >
                <h1>Huge Categories</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. </p>
              </div> */}
              
            {/* {catRender(data,0,2,['col-lg-6','col-lg-3','col-lg-3'])}
            {catRender(data,3,4 ,['col-lg-6','col-lg-6','col-lg-6','col-lg-6','col-lg-6'])}
            {catRender(data,5,7 ,['col-lg-6','col-lg-6','col-lg-3','col-lg-3','col-lg-4','col-lg-3','col-lg-6','col-lg-3  '])} */}

            {catRender(data,0,10 ,['col-lg-6','col-lg-6','col-lg-6','col-lg-6'])}
            {/* {catRender(data,3,4 ,['col-lg-6','col-lg-6','col-lg-6','col-lg-6','col-lg-6'])}
            {catRender(data,5,7 ,['col-lg-6','col-lg-6','col-lg-3','col-lg-3','col-lg-4','col-lg-3','col-lg-6','col-lg-3  '])} */}

           
              
            </div>
          </div>
        </div>
  );
};

CategorySixSingle.propTypes = {
  data: PropTypes.object
};

export default CategorySixSingle;
