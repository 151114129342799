export const ApiEndpointUrl = "https://hugecycles.com/hugeadmin/public/api/";
export const storageUrl = "https://hugecycles.com/hugeadmin/public/storage";
export const clientLogoUrl = "https://hugecycles.com/hugeadmin/public/cache/large/";
export const frontUrl = "https://hugecycles.com";


export const Logintoken = ()=>{
      
    if(localStorage.getItem('auth') !=undefined)
    {
        return JSON.parse(localStorage.getItem('auth')).token
    }
    return '';

}
export default {};
