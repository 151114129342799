import PropTypes from "prop-types";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { frontUrl, storageUrl } from "../../common";
import ProductGrid from "./ProductGrid";
import SectionTitle from "../../components/section-title/SectionTitle";
import ProductGridThree from "./ProductGridThree";
import { connect } from "react-redux";

const TabProductThree = ({ spaceBottomClass, category, spaceTopClass,
  bgColorClass,
  sectionTitle,
  productTabClass,
  product }) => {


    console.log("product data coming here",product);
  return (
    <div style={{ backgroundImage: `url("${storageUrl}/configuration/dot-bg.jpg")` }}
      className={`product-area hm9-section-padding bg-shaded-light  pt-40 ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container-fluid">
      <div className="welcome-content text-center" >
          {/* <h5>Who Are We</h5> */}
          <h1>New Arrivals</h1>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt labor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commo consequat irure </p> */}
        </div>
        <Tab.Container defaultActiveKey="featured">
          {/* <Nav variant="pills" className="product-tab-list pt-50 pb-55 text-center"> */}
          <Nav variant="pills" className="product-tab-list  text-center">
            {/* <Nav.Item>
              <Nav.Link eventKey="newArrival">
                <h4>New Arrivals</h4>
              </Nav.Link>
            </Nav.Item> */}
            {/* <Nav.Item>
              <Nav.Link eventKey="bestSeller">
                <h4>Best Sellers</h4>
              </Nav.Link>
            </Nav.Item> */}
            {/* <Nav.Item>
              <Nav.Link eventKey="saleItems">
                <h4>Sale Items</h4>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="featured">
              <div className="custom-row-4">
                <ProductGridThree
                 category="featured"
                  products={product.items}
                  type="featured"
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="bestSeller">
              <div className="custom-row-4">
                <ProductGridThree
                 category="bestseller"
                  type="bestSeller"
                  products={product.bestseller.items}
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="saleItems">
              <div className="custom-row-4">
                <ProductGridThree
                  category="salesitems"
                  type="saleItems"
                  limit={10}
                  products={product.saleItem.items}
                  spaceBottomClass="mb-35"
                />
              </div>  
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

// TabProductThree.propTypes = {
//   category: PropTypes.string,
//   spaceBottomClass: PropTypes.string
// };


TabProductThree.propTypes = {
  bgColorClass: PropTypes.string,
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  sectionTitle: PropTypes.bool,
  productTabClass: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    product: state.product,
  };
};
   
export default connect(mapStateToProps)(TabProductThree);
