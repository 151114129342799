import PropTypes from "prop-types";
import React from "react";
import { frontUrl, storageUrl } from "../../common";
import { Fragment } from "react";
const HomeAbout = ({ }) => {
    return (
        <Fragment>
           <div class="home-about hm6-section-padding pt-40 pb-60" style={{ backgroundColor:"#f0f0f0" }}>
              <div class="container-fluid" >
                <div className="welcome-content text-center" >
                  <h1>About Huge</h1>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div style={{ height: "400px", backgroundSize: "contain", background: `url("${storageUrl}/configuration/about-home.png") no-repeat center` }}>
                    </div>
                    <img scr="https://hugecycles.com/hugeadmin/public/storage/configuration/about-home.png"></img>
                  </div>
                  <div className="col-lg-6 m-auto">
                    <p><strong>HUGE</strong> is a name that resonates a new age brand that has kept the tradition of bringing innovative products in the market. Built to perform and made to perfection, this range of Premium bicycles is manufactured in state-of-the-art facility to meet international standards. <br /><br /> From aerodynamic designs, enthralling graphics, hi-tech features to eye catching colours, <strong>HUGE</strong> bikes offer everything which a true biker needs and demands. Each bike is crafted with passion giving its rider a delightful riding experience keeping due focus on safety, durability, comfort & style. Over a short period of time, <strong>HUGE</strong> Cycles has won the hearts of its thousands of satisfied bikers. The Bicycles range includes MTB Single speed & Multi speed, Fat Bikes & Kids Bikes. Take your bike experience to a whole new dimension with <strong>HUGE</strong> Cycles!</p>
                  </div>
                </div>
              </div>
	          </div>
        </Fragment>
    );
};
export default HomeAbout;