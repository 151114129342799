import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../helpers/product";
import ProductModal from "./ProductModal";

const ProductGridSingleThree = ({
  product,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();

  const discountedPrice = getDiscountPrice(product.price, product.discount);
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2);
  if(product.images.length<1)
  {
      return (<p> </p>);
  }
  return (
    <Fragment>
      <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
        <div
          className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""
            }`}
        >
          <div className="product-img">
            <Link to={process.env.PUBLIC_URL + "/product/" + product.url_key}>
              <img
                className="default-img"
                src={product.images[0].url}
                alt=""
              />
              {product.images.length > 1 ? (
                <img
                  className="hover-img"
                  src={product.images[1].url}
                  alt=""
                />
              ) : (
                ""
              )}
            </Link>




            {product.discount || product.new ? (
              <div className="product-img-badges">
                {product.discount ? (
                  <span className="pink">-{product.discount}%</span>
                ) : (
                  ""
                )}
                {product.new ? <span className="purple">New</span> : ""}
              </div>
            ) : (
              ""
            )}


            {/* 
            <div className="product-action-2">
              {product.affiliateLink ? (
                <a
                  href={product.affiliateLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Buy now"
                >
                  {" "}
                  <i className="fa fa-shopping-cart"></i>{" "}
                </a>
              ) : product.variation && product.variation.length >= 1 ? (
                <Link
                  to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                  title="Select options"
                >
                  <i class="fa fa-cog"></i>
                </Link>
              ) : product.stock && product.stock > 0 ? (
                <button
                  onClick={() => addToCart(product, addToast)}
                  className={
                    cartItem !== undefined && cartItem.quantity > 0
                      ? "active"
                      : ""
                  }
                  disabled={cartItem !== undefined && cartItem.quantity > 0}
                  title={
                    cartItem !== undefined ? "Added to cart" : "Add to cart"
                  }
                >
                  {" "}
                  <i className="fa fa-shopping-cart"></i>{" "}
                </button>
              ) : (
                <button disabled className="active" title="Out of stock">
                  <i className="fa fa-shopping-cart"></i>
                </button>
              )}

              <button onClick={() => setModalShow(true)} title="Quick View">
                <i className="fa fa-eye"></i>
              </button>

              <button
                className={compareItem !== undefined ? "active" : ""}
                disabled={compareItem !== undefined}
                title={
                  compareItem !== undefined
                    ? "Added to compare"
                    : "Add to compare"
                }
                onClick={() => addToCompare(product, addToast)}
              >
                <i className="fa fa-retweet"></i>
              </button>

              <button
                className={wishlistItem !== undefined ? "active" : ""}
                disabled={wishlistItem !== undefined}
                title={
                  wishlistItem !== undefined
                    ? "Added to wishlist"
                    : "Add to wishlist"
                }
                onClick={() => addToWishlist(product, addToast)}
              >
                <i className="fa fa-heart-o" />
              </button>
            </div> 
        */}



          </div>
          <div className="product-content-2">
            <div className="title-price-wrap-2">
              <h3>
                <Link to={process.env.PUBLIC_URL + "/product/" + product.url_key}>
                  {product.name}
                </Link>
              </h3>
              <Link to={process.env.PUBLIC_URL + "/product/" + product.url_key}>
              <h5 dangerouslySetInnerHTML={{__html: product.short_description.replace(/<[^>]+>/g, ''),}}></h5>
              </Link>

              {/* <div className="price-2">
                {discountedPrice !== null ? (
                  <Fragment>
                    <span>
                      {currency.currencySymbol + finalDiscountedPrice}
                    </span>{" "}
                    <span className="old">
                      {currency.currencySymbol + finalProductPrice}
                    </span>
                  </Fragment>
                ) : (
                  <span>{currency.currencySymbol + finalProductPrice} </span>
                )}
              </div> */}



            </div>
          </div>
        </div>
      </div>




      {/* <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
    <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
        <div class="product-img">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img class="default-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo1.jpg"} alt="" />
                <img class="hover-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo1.jpg"} alt="" />
            </Link> */}

      {/* <div class="product-action-2">
                <button disabled="" class="active" title="Add to cart"><i class="fa fa-shopping-cart"></i></button>
                <button title="Quick View"><i class="fa fa-eye"></i></button>
                <button class="" title="Add to compare"><i class="fa fa-retweet"></i></button>
                <button class="" title="Add to wishlist"><i class="fa fa-heart-o"></i></button>
            </div> */}

      {/* </div>
        <div class="product-content-2">
            <div class="title-price-wrap-2">
                <h2>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"HUGE"}</Link>
                </h2>
                <h3>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"product name"}</Link>
                </h3> */}
      {/* <div class="price-2"><span>Rs.4500.00 </span></div> */}
      {/* </div>
        </div>
    </div>
</div> */}



      {/* <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
    <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
        <div class="product-img">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img class="default-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo2.jpg"} alt="" />
                <img class="hover-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo2.jpg"} alt="" />
            </Link> */}

      {/* <div class="product-action-2">
                <button disabled="" class="active" title="Add to cart"><i class="fa fa-shopping-cart"></i></button>
                <button title="Quick View"><i class="fa fa-eye"></i></button>
                <button class="" title="Add to compare"><i class="fa fa-retweet"></i></button>
                <button class="" title="Add to wishlist"><i class="fa fa-heart-o"></i></button>
            </div> */}

      {/* </div>
        <div class="product-content-2">
            <div class="title-price-wrap-2">
                <h2>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"HUGE"}</Link>
                </h2>
                <h3>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"product name"}</Link>
                </h3> */}
      {/* <div class="price-2"><span>Rs.4500.00 </span></div> */}
      {/* </div>
        </div>
    </div>
</div> */}



      {/* <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
    <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
        <div class="product-img">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img class="default-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo3.jpg"} alt="" />
                <img class="hover-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo3.jpg"} alt="" />
            </Link> */}

      {/* <div class="product-action-2">
                <button disabled="" class="active" title="Add to cart"><i class="fa fa-shopping-cart"></i></button>
                <button title="Quick View"><i class="fa fa-eye"></i></button>
                <button class="" title="Add to compare"><i class="fa fa-retweet"></i></button>
                <button class="" title="Add to wishlist"><i class="fa fa-heart-o"></i></button>
            </div> */}

      {/* </div>
        <div class="product-content-2">
            <div class="title-price-wrap-2">
                <h2>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"HUGE"}</Link>
                </h2>
                <h3>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"product name"}</Link>
                </h3> */}
      {/* <div class="price-2"><span>Rs.4500.00 </span></div> */}
      {/* </div>
        </div>
    </div>
</div> */}



      {/* <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
    <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
        <div class="product-img">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img class="default-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo4.jpg"} alt="" />
                <img class="hover-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo4.jpg"} alt="" />
            </Link> */}

      {/* <div class="product-action-2">
                <button disabled="" class="active" title="Add to cart"><i class="fa fa-shopping-cart"></i></button>
                <button title="Quick View"><i class="fa fa-eye"></i></button>
                <button class="" title="Add to compare"><i class="fa fa-retweet"></i></button>
                <button class="" title="Add to wishlist"><i class="fa fa-heart-o"></i></button>
            </div> */}

      {/* </div>
        <div class="product-content-2">
            <div class="title-price-wrap-2">
                <h2>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"HUGE"}</Link>
                </h2>
                <h3>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"product name"}</Link>
                </h3> */}
      {/* <div class="price-2"><span>Rs.4500.00 </span></div> */}
      {/* </div>
        </div>
    </div>
</div> */}



      {/* <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
    <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
        <div class="product-img">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img class="default-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo5.jpg"} alt="" />
                <img class="hover-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo5.jpg"} alt="" />
            </Link> */}

      {/* <div class="product-action-2">
                <button disabled="" class="active" title="Add to cart"><i class="fa fa-shopping-cart"></i></button>
                <button title="Quick View"><i class="fa fa-eye"></i></button>
                <button class="" title="Add to compare"><i class="fa fa-retweet"></i></button>
                <button class="" title="Add to wishlist"><i class="fa fa-heart-o"></i></button>
            </div> */}

      {/* </div>
        <div class="product-content-2">
            <div class="title-price-wrap-2">
                <h2>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"HUGE"}</Link>
                </h2>
                <h3>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"product name"}</Link>
                </h3> */}
      {/* <div class="price-2"><span>Rs.4500.00 </span></div> */}
      {/* </div>
        </div>
    </div>
</div> */}



      {/* <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
    <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
        <div class="product-img">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img class="default-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo6.jpg"} alt="" />
                <img class="hover-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo6.jpg"} alt="" />
            </Link> */}

      {/* <div class="product-action-2">
                <button disabled="" class="active" title="Add to cart"><i class="fa fa-shopping-cart"></i></button>
                <button title="Quick View"><i class="fa fa-eye"></i></button>
                <button class="" title="Add to compare"><i class="fa fa-retweet"></i></button>
                <button class="" title="Add to wishlist"><i class="fa fa-heart-o"></i></button>
            </div> */}

      {/* </div>
        <div class="product-content-2">
            <div class="title-price-wrap-2">
                <h2>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"HUGE"}</Link>
                </h2>
                <h3>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"product name"}</Link>
                </h3> */}
      {/* <div class="price-2"><span>Rs.4500.00 </span></div> */}
      {/* </div>
        </div>
    </div>
</div> */}



      {/* <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
    <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
        <div class="product-img">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img class="default-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo7.jpg"} alt="" />
                <img class="hover-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo7.jpg"} alt="" />
            </Link> */}

      {/* <div class="product-action-2">
                <button disabled="" class="active" title="Add to cart"><i class="fa fa-shopping-cart"></i></button>
                <button title="Quick View"><i class="fa fa-eye"></i></button>
                <button class="" title="Add to compare"><i class="fa fa-retweet"></i></button>
                <button class="" title="Add to wishlist"><i class="fa fa-heart-o"></i></button>
            </div> */}

      {/* </div>
        <div class="product-content-2">
            <div class="title-price-wrap-2">
                <h2>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"HUGE"}</Link>
                </h2>
                <h3>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"product name"}</Link>
                </h3> */}
      {/* <div class="price-2"><span>Rs.4500.00 </span></div> */}
      {/* </div>
        </div>
    </div>
</div> */}



      {/* <div className={`custom2-col-5 ${sliderClassName ? sliderClassName : ""}`}>
    <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
        <div class="product-img">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img class="default-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo8.jpg"} alt="" />
                <img class="hover-img" src={process.env.PUBLIC_URL + "/assets/img/demo-products/demo8.jpg"} alt="" />
            </Link> */}

      {/* <div class="product-action-2">
                <button disabled="" class="active" title="Add to cart"><i class="fa fa-shopping-cart"></i></button>
                <button title="Quick View"><i class="fa fa-eye"></i></button>
                <button class="" title="Add to compare"><i class="fa fa-retweet"></i></button>
                <button class="" title="Add to wishlist"><i class="fa fa-heart-o"></i></button>
            </div> */}

      {/* </div>
        <div class="product-content-2">
            <div class="title-price-wrap-2">
                <h2>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"HUGE"}</Link>
                </h2>
                <h3>
                    <Link to={process.env.PUBLIC_URL + "/"}>{"product name"}</Link>
                </h3> */}
      {/* <div class="price-2"><span>Rs.4500.00 </span></div> */}
      {/* </div>
        </div>
    </div>
</div> */}






      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedprice={discountedPrice}
        finalproductprice={finalProductPrice}
        finaldiscountedprice={finalDiscountedPrice}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtocart={addToCart}
        addtowishlist={addToWishlist}
        addtocompare={addToCompare}
        addtoast={addToast}
      />
    </Fragment>
  );
};

ProductGridSingleThree.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default ProductGridSingleThree;
